body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


a {
  color: inherit;
  text-decoration: none;
}

.logonimage img {
  height: 82%;
  position: relative;
  right: 110px;
  width: 48%;
}
@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}
/* .logo img {
  width: 70%;
  margin-top: 1%;
} */
/* WebKit browsers (Chrome, Safari, etc.) */
::-webkit-scrollbar {
  width: 12px !important; /* Scrollbar ki width badhayein */
}

::-webkit-scrollbar-thumb {
  background-color: #888 !important; /* Scrollbar ka color set karein */
  border-radius: 6px; /* Scrollbar thumb ke border ko round karein */
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1 !important; /* Scrollbar track ka color set karein */
}

.logo img {
  width: 64%;
  margin-top: 1%;
}
.col-2.demoimg {
  padding-top: 15px;
  border-right: 1px solid;
}
.list .col-4 {
  border-right: 1px solid;
}
.wedetail.col-8 {
 padding-top: 9px;
}
.demo li {
  text-decoration: none;
  display: block;
}
 .list span {
  font-weight: 900;

}
.demo2 span {
  font-weight: 900;
  font-size: 14px;
  color: red;
}
.blinking-text {
    animation: blink 1s infinite;
    color: white;
  }
  
  @keyframes blink {
    0% {
      color: white;
    }
    50% {
      color: red;
    }
    100% {
      color: white;
    }
  }
  .blinking-image p{
  font-weight:700;
  font-size:20px;
  }
  .blinking-image {
    animation: blink1 1s infinite;
    color: red; /* Initial color */
  }
  
  @keyframes blink1 {
    0% {
      color: red;
    }
    33.33% {
      color: blue;
    }
    66.66% {
      color: black;
    }
    100% {
      color: green; /* Final color */
    }
  }
  
  .aboutbtn button {
    border-radius: 2em;
    color:#fff;
    font-weight:500;
    border:none;
    padding: 10px 24px;
    margin-top: 8%;
  background: linear-gradient(to right, #0009add4 0%,#ff5800f7 100%);
  box-shadow: 0 10px 15px 0px rgba(56, 0, 189, .2);
  }
  .aboutbtn1 button {
    border-radius: 2em;
    font-weight:500;
    border:none;
    padding: 10px 24px;
    margin-top: 8%;
    background: linear-gradient(to right, #673AB7 0%,#2196F3 100%);
    box-shadow: 0 10px 15px 0px rgba(56, 0, 189, .2);
    color:#fff;
  }
.demoimg a {
    font-size: 21px;
}
.row.ludo {
  margin-top: 8%;
}
.sliderimage{
  display:none;
}
.col-3.ludo1 {
  text-align: center;
  margin-top:10px;
}
.ludo1 img {
  width: 78%;
  height: auto;
  border-radius: 21px;
  box-shadow: 1px 6px 11px 2px #80808066;
  margin-top: 26%;
}
.demo1.col-8 {
  padding: 13px 1px;
}
.demoimg img {
  width: 32%;
  margin-left: 17px;
  vertical-align: middle;
}
.demo1 p {
  margin-top: 0;
  margin-bottom: -2px;
}
/* .ludo1{
  background-image: url(./images/phonebg.jpeg);
  background-repeat: no-repeat;
} */
/* .container-fluid.demo {
  margin-top: 0%;
  position: fixed;
  background-color: #fff;
  overflow: hidden;
  padding: 15px 10px 10px 10px;
  top: 0px;
  z-index: 1;
} */
.container-fluid.demo {
  margin-top: 0%;
  position: fixed;
  background-color: #fff;
  overflow: hidden;
  padding: 2px 10px 1px 10px;
  top: 0px;
  z-index: 1;
}
.demo1 {
  border-radius: 18px;
  background-color: #0080008f;
  color: #fff;
  font-weight: 500;
  padding: 8px 0 11px 14px;

}

.demo2 p {
  margin-top: 0;
  margin-bottom: 0px;
}
.imgwhatsapp img {
  width: 32px;
  margin-top: -4px;
  padding-bottom: 2px;
}
.demo2 button {
  position: absolute;
  right: 86px;
  border: none;
  top: 7px;
  padding: 6px 27px 2px 19px;
  background: linear-gradient(to right, #673AB7 0%,#2196F3 100%);
  /* background-color: #008000a1; */
  color: #fff;
  font-family: 'Poppins', sans-serif;
  box-shadow: 0 10px 15px 0px rgba(56, 0, 189, .2);
  border-radius: 9em;
  transition: top 0.5s ease, right 0.5s ease;


}
.arrowdown {
  position: relative;
  bottom: 40px;
  left: 210px;
  font-size: 22px;
}  

/* .demo2 button {
  position: absolute;
  right: 33px;
  border: none;
  top: 15px;
  background-color: #008000a1;
  color: #fff;
  border-radius: 10px;
  padding: 7px 47px 10px 49px;
} */
.demo2 i.fa-brands.fa-whatsapp {
  font-size: 44px;
  color: #fff;
  border-radius: 72px;
  background-color: #2ae82a85;
}
.row.Features {
  margin-top: 4%;
  padding: 2% 7%;
  text-align: justify;

}
.callludo img{
  width:20px;
 margin: 0 0 0 10px;
}
.callludo{
  text-align: center;
  margin-bottom:-8%;
}
.callludo span.callarrow {
  margin-top: -30px;
  position: relative;
  bottom: 4px;
}
.callludo span {
  font-weight: 700;
  font-size: 27px;
}
/* .Features h3 {
  font-size: 27px;
  margin-bottom: 3px;
} */
.Features h3 {
  font-size: 24px;
  margin-bottom: 3px;
  background: linear-gradient(to right, #0009add4 0%,#ff5800f7 100%);
  width: 22%;
  color: #fff;
  padding: 10px 36px;
  border-radius: 17px;
}
.demo2 a{
  text-decoration:none;
}
.demo3 p{
  margin-bottom: 12px;
  line-height: 18px;
  margin-left: 44px;
}
.Features img {
  width: 15%;
  margin-left: 24px;
  margin-top: 9px;
}
.backgclr{
background-color: rgb(203 213 225 / var(--tw-bg-opacity));
background-color:#dee6f6d6;
padding: 1px;
}
/* footer */

.footer{
  position:fixed;
  bottom:0px;
  background-color:#fff;
  text-align:center;
  z-index:1;
}

/* .footerdemoimg {
  text-align: center;
  font-weight: 700;
  font-size: 25px;
  margin-bottom: 1%;
  margin-top: 1px;
  
} */
.footerdemoimg img {
  width: 17%;
  margin-top: -3px;
}
.footer .logomy img {
  width: 71px;
  margin-top: 6px;
}
.row.footerlist {
  margin-top: 1%;
 padding-bottom: 5PX;
}
.b1 button {
  border: none;
  padding: 2px 17px;
  margin-right: 7%;
  border-radius: 2em;
  background: linear-gradient(to right, #0009add4 0%,#ff5800f7 100%);
  color: #fff;
  font-size: 12px;
  font-weight: 700;
}


.b1 img {
    width: 23px;
    margin-right: 5px;
}
.b2 button {
  border: none;
  padding: 4px 3px !important;
  margin-left: 15%;
  border-radius: 2em;
  background: #673AB7;
  background: linear-gradient(to right, #673AB7 0%,#2196F3 100%);
  box-shadow: 0 10px 15px 0px rgba(56, 0, 189, .2);
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  height: 29px;
}
.b2 img{
 
    width: 22%;
    margin-right: 7px;
}
.b3 button{
  width: 30%;
   border: none;
  width: 27%;
 background:#fff;
}
.b3 img {
  width: 122px;
  border-radius: 30px;
  padding: 1px 13px;
  margin-left: -40px;
}    
.footer a{
  text-decoration:none;
  color:#413737c9;
}
/* close footer */

/* features */


.Features h5 {
  font-size: 20px;
  color: #ff5722; /* Change the color to your preference */
  margin:20px 0px;
}

/* Style list items */
.Features ul {
  list-style-type: disc; /* You can change this to other list styles like 'circle', 'square', etc. */
  margin-left: 20px; /* Adjust the left margin to your liking */
}

.Features li {
  font-size: 16px;
  color: #333; /* Change the color to your preference */ 
  margin-left:8%
}

/* Add background color to the container */
/* .container-fluid.features_content {
  background-color: #f2f2f2; /* Change the background color to your preference */
  /* padding: 20px; */
/* } */ 
/* close features */

.contact{
  margin:5% 0;
  padding:0 0 74px 0; 
}
.contact h2 {
  text-align: left;
  font-weight: 700;
  margin-bottom: 5%;
  color: #fff;
  border-radius: 15px;
  padding: 3px 18px;
  font-size: 25px;
  background: linear-gradient(to right, #0009add4 0%,#ff5800f7 100%);
}
.contact h4 {
  font-weight: 700;
  color: #ff0000a8;
}
.contact span {
  color: black;
}
.skype img {
  width: 11%;
  margin-top: 11px;
  margin-left: 213px;
}
h5.row.con {
  margin-left: 198px;
}
.contact li {
  display: inline-block;
  margin: 10px;
  padding: 1px 46px;
  font-size: 14px;
 }
 .condition ul li:first-child {
  border-right: 1px solid black;
  padding-right: 73px;
  margin-top: 2%;
  margin-right: 2%;
}
.condition a{
  text-decoration:none;
}
.condition ul {
  border-top: 1px solid gray;
  width: 67%;
  margin-top: 3%;
  margin-left: 195px;
}
 .contact{
 border-bottom:1px solid gray;
 }
.contactno img {
  width: 3%;
  margin-right: 2%;
}

@media only screen and (max-width: 1280px){
  /* .demo2 button {
    position: absolute;
    border: none;
    top: 7px;
    padding: 6px 27px 2px 19px;
    background: linear-gradient(to right, #673AB7 0%,#2196F3 100%);
    /* background-color: #008000a1; */
    /* color: #fff;
    font-family: 'Poppins', sans-serif;
    box-shadow: 0 10px 15px 0px rgba(56, 0, 189, .2);
    border-radius: 9em;
    transition: top 0.5s ease, right 0.5s ease; */
/* } */ 

}



@media only screen and (width: 912px){  
.contact {
  margin: 2% 0 17% 0;
  padding: 0 0 74px 0;
}
.footer {
  position: fixed;
  bottom: 0px;
  background-color: #fff;
  text-align: center;
  z-index:1000;
}

.condition ul {
  width: 85%;
}
.condition ul li:first-child {
  border-right: 1px solid black;
  padding-right: 57px;
  margin-top: 2%;
  margin-right: 53px;
}
.contact li {
  display: inline-block;
  margin: 2px;
  padding: 1px 0px;
  font-size: 16px;
}

.logo{
  margin-top: -15px;
}
.logo img {
  width: 70%;
  margin-top: 31%;
}
.Features h3 {
  font-size: 27px;
  margin-bottom: 3px;
  background: linear-gradient(to right, #0009add4 0%,#ff5800f7 100%);
  width: 37%;
  color: #fff;
  padding: 10px 36px;
  border-radius: 17px;
}
.row.Features {
  margin-top: 4%;
  padding: 2% 7%;
  text-align: justify;
 
}
h5.row.con {
  margin-left: 72px;
}
.condition ul {
  border-top: 1px solid gray;
  width: 85%;
  margin-top: 3%;
  margin-left: 52px;
}

}

@media only screen and (max-width: 820px){
  .col-3.ludo1 {
    text-align: center;
    margin-top: 10px;
    width: 50%;
}

.b3 button {
  width: 30%;
  border: none;
  width: 27%;
  margin-right: 79%;
  background: #fff;
}
.demo2 button {
  top: 2px;
  padding: 2px 27px 0px 19px;
}
.imgwhatsapp img {
  width: 28px;
  margin-top: -2px;
  padding-bottom: 2px;
}
.footer {
  position: fixed;
  bottom: 0px;
  background-color: #fff;
}
.condition ul {
  width: 82%;
  margin-left: 34px;
}
.condition ul li:first-child {
  border-right: 1px solid black;
  padding-right: 65px;
  margin-top: 2%;
  margin-right: 4px;
  margin-left: -37px;
}
.contact li {
  display: inline-block;
  margin: 0;
  padding: 1px 46px;
  font-size: 16px;
}
h5.row.con {
  margin-left: 62px;
}
}
@media only screen and (max-width: 768px){

  .b3 button {
    width: 30%;
    border: none;
    width: 27%;
    margin-right: 77%;
    background: #fff;
}


}

@media only screen and (max-width: 540px){
  .backgclr {
    background-color: rgb(203 213 225 / var(--tw-bg-opacity));
    background-color: #dee6f6d6;
    padding: 2px 27px 0 2px;
  }
    .Features h3 {
      font-size: 27px;
      margin-bottom: 3px;
      background: linear-gradient(to right, #0009add4 0%,#ff5800f7 100%);
      width: 67%;
      color: #fff;
      padding: 10px 36px;
      border-radius: 17px;
}
.row.Features {
  margin-top: 4%;
  padding: 15% 7% 5% 7%;
  text-align: justify;
  
}
.blinking-image p {
  font-weight: 700;
  font-size: 13px;
  margin-top: 7px;
}

  .col-3.ludo1 {
    width: 50%;
  }


  
  .footer {
    position: fixed;
    bottom: 0px;
    background-color: #fff;
    padding-top: 8px;
    /* padding-bottom: 71px; */
    /* height: 0.3%; */
}
 .b2 img {
  width: 16%;
  margin-right: 7px;
}

.logo img {
  width: 100%;
  margin-top: 14%;
}
.b3 img {
  width: 122px;
  border-radius: 30px;
  padding: 1px 12px;
  margin-left: -23px;

}
.footer-demoimg img {
  width: 34px;
}
.b1 button {
  border: none;
  padding: 2px 10px;
  margin-right: 7%;
  border-radius: 2em;
  background: linear-gradient(to right, #0009add4 0%,#ff5800f7 100%);
  color: #fff;
  font-size: 12px;
  font-weight: 700;
}
.footerdemoimg img {
  width:29%;
}
.demo2 button {
  top: 5px;
  padding: 2px 21px;
  right: 18px;
}


.condition ul li:first-child {
  padding-right: 22px;
  margin-right: -10px;
  margin-left: -67px;
}
h5.row.con {
  margin-left: 17px;
  font-size: 12px;
}
.condition ul {
  width: 82%;
  margin-left: 17px;
}
}
@media only screen and (max-width: 414px){
  .logo img {
    width: 145%;
    margin-top: 5%;
}
.call img {
  width: 6%;
  margin-left: 18px;
}

.call h5 {
  font-size: 16px;
  margin: -42px 0 3px 0;
  text-align: center;
}
.container-fluid.demo {
  margin-top: 0%;
  position: fixed;
  background-color: #fff;
  overflow: hidden;
  padding: 15px 10px 10px;
  top: -10px;
  z-index: 1;
}

.footer {
  position: fixed;
  bottom: 0px;
  background-color: #fff;
}
.slick-prev {
  left: -25px;
  z-index: 1000;
}
.col-8.skype img {
  width: 36%;
  margin-top: 4px;
}
.demo2 button {
  padding: 6px 27px 4px 19px;
  margin-right: -15px;
  margin-top: 14px;
}
.slick-prev, .slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 98px !important;
  height: 20px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: black!important;
  border: none;
  outline: none;
  background: transparent;
}
.slick-next:before, .slick-prev:before {
  color: black !important;
}
  .sliderimage {
    display: block;
    padding: 111px 0 0 10px;
}
.slick-slide img {
  display: block;
  width: 60%;
  margin: 11px 75px;
}
.slick-dots {
display: none !important;
}
.myludo{
  display:none;
}
.slick-next:before, .slick-prev:before {
  font-size: 20px;
  line-height: 1;
  opacity: .75;
  color: black;
}
.slick-next, .slick-prev {
  font-size: 0;
  line-height: 0;
  top: 50%;
  width: 92px;
  height: 20px;
  -webkit-transform: translate(0,-50%);
  -ms-transform: translate(0,-50%);
  transform: translate(0,-50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  background: 0 0;
}

.row.Features {
  margin-top: 4%;
  padding: 15% 7% 5% 7%;
  text-align: justify;

}
.footer .col-3 {
  flex: 0 0 auto;
  width: 49.333333%;
}

.footerdemoimg img {
  width: 15%;
}
.b3 img {
  width: 122px;
  border-radius: 30px;
  padding: 1px 12px;
  margin-left: 12px;
}
.b1 button {
  border: none;
  padding:2px 24px;
  margin-right: 0%;
  margin-left: 14px;
  border-radius: 2em;
  background: linear-gradient(to right, #0009add4 0%,#ff5800f7 100%);
  color: #fff;
  font-size: 12px;
  font-weight: 700;
}
.contactno img {
  width: 8%;
  margin-right: 2%;
}
.footer {
  position: fixed;
  bottom: 0px;
  background-color: #fff;
}
.contact li {
  padding: 1px 31px;
  font-size: 14px;
}
.skype img {
  width: 35%;
  margin-top: 11px;
  margin-left: 69px;
}

}
@media only screen and (max-width: 393px){
  .logo img {
    width: 123%;
    margin-top: 5%;
}
.footer {
  position: fixed;
  bottom: 0px;
  background-color: #fff;
}
.demo2 button {
  padding: 6px 27px 4px 19px;
  margin-right: -8px;
  margin-top: 8px;
}
.condition ul li:first-child {
  padding-right: 9px;
  margin-right: -21px;
  margin-left: -67px;
}

}
@media only screen and (max-width: 375px){
  .logo img {
    width: 120%;
    margin-top: 7%;
}
.footerdemoimg img {
  width:15%;
  margin-top: -3px;
}

.b1 button {
  border: none;
  padding: 2px 19px;
}
.b2 img {
  width: 15%;
  margin-right: 5px;
}
.contact li {
  padding: 1px 27px;
  font-size: 14px;
}
}
@media only screen and (max-width: 360px){
  .footer .col-5 {
    flex: 0 0 auto;
    width: 99.666667%;
    display: contents;
    padding: 1px 13px;
}

.footer {
  position: fixed;
  bottom: 0px;
  background-color: #fff;
}
.contact li {
  padding: 1px 19px;
  font-size: 10px;
}
.condition ul li:first-child {
  padding-right: 9px;
  margin-right: -13px;
  margin-left: -43px;
}
.logo img {
  width: 152%;
  margin-top: 3%;
}

}
@media only screen and (max-width: 280px){

  .slick-slide img {
    display: block;
    width: 60%;
    margin: 11px 52px;
}
.logo img {
  width: 34px;
  margin-top: 0%;
}
.b1 img {
  width: 20px;
  margin-right: 0px;
}
.arrowdown {
  position: relative;
  bottom: 40px;
  left: 138px;
  font-size: 22px;
}
.demo2 span {
  font-weight: 900;
  font-size: 13px;
  color: red;
}
.demo2 button {
  padding: 5px 27px 1px 19px;
  margin-right: -11px;
  margin-top: 10px;
}
.Features h3 {
  font-size: 20px;
  padding: 10px 27px;
}
.Features h5 {
  font-size: 16px;
}
.Features li {
  font-size: 15px;
  color: #333;
  margin-left: 13%;
}
.Features ul {
  list-style-type: disc;
  margin-left: 7px;
}
.footer .col-8 {
  flex: 0 0 auto;
  width: 100.666667%;
}
.footer p {
  margin: 0px 0px 0 0px;
  font-size: 13px;
}
.footerdemoimg {
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 0%;
  margin-top: 0px;
}
.footer {
  position: fixed;
  bottom: 0px;
  background-color: #fff;
}
.b1 button {
  border: none;
  padding: 2px 2px;
  width: 85%;
}
.b2 button {
  width: 93%;
  margin-left: -27%;
  padding: 0px 0 2px 3px !important;
}
.row.Features {
  margin-top: 4%;
  padding: 15% 7% 5% 7%;
  text-align: justify;
 
}
.b3 img {
  width: 122px;
  border-radius: 2em;
  padding: 1px 12px;
  margin-left: -28px;
}
.contactno .col-3 {
  flex: 0 0 auto;
  width: 52%;
}
.contactno .col-8 {
  flex: 0 0 auto;
  width: 99%;
}
.condition ul {
  width: 106%;
}
.contact li {
  padding: 1px 3px;
  font-size: 11px;
}
.condition ul li:first-child {
  padding-right: 17px;
  margin-right: 18px;
  margin-left: -27px;
}
}